export const timezone_constants = {
  country_dial_code: {
    us: +1,
    in: +91,
  },
  default_internation_timezone: {
    country_code: "US",
    offset: "-0800",
    value: "America/Los_Angeles",
    label: "America/Los_Angeles (GMT -0800)",
  },
  country_key: {
    us: "us",
    in: "in",
  },
};
