const SessionStorageConstants = {
  GUEST_USER_NAME: "guest__username",
  FROM_ROUTE: "fromRoute",
  EDIT: "edit",
  LISTING_CUSTOMER_ID: "listing_customers_id",
  LISTING_CUSTOMER_TYPE: "listing_customers_type",
  LISTING_CUSTOMERS_IS_NO_SCHEDULE_SUBSCRIPTION:
    "listing_customers_is_no_schedule_subscription",
  CUSTOMER: "customer",
  AVAILABILITY: "availability",
  RECIPIENT_LIST: "recipient_list",
  SUBSCRIPTION_PERMISSION: "subscription_permission",
  MENU_PERMISSIONS: "menu_permissions",
  ENTITY_TYPE: "entity_type",
  CUSTOM_FILTERS: "customfilter",
  ROUTE_COMING_FROM: "routeComingFrom",
  AD_CAMPAIGN_DETAIL: "ad_campaign_detail",
  CALENDAR_PERMISSION: "calendar_permission",
  PROMOTIONAL_LISTING: "promitionalListing",
  MULTIPLE_PURCHASES: "multiple_purchases",
  PLAN_ID: "plan_id",
  ENTITY_DATA: "entity_data", // this represents the data of wrapper(parent) on offering, ex. plan_data or ads_data
  IS_SUBSCRIPTION_PAYMENT_TYPE: "is_subscription_payment_type",
  PLAN_OFFERING_TYPE_DATA: "PLAN_OFFERING_TYPE_DATA",
  listingId: "listingId",
  ACTIVE_EXLY_CONNECTED_TAB: "ACTIVE_EXLY_CONNECTED_TAB",
  REFUND_REQUEST_ID: "REFUND_REQUEST_ID", // refund request id to fetch the paginated refund data of the selected transactions
  LAST_PRELOADER_TEXT: "lastPreloaderText",
};

export default SessionStorageConstants;
