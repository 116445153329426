export const SUBDOMAIN_VALIDATION_REGEX_PATTERN =
  "^(?!-)[a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*$";
export const NUMERIC_REGEX = /^\d+$/;
export const SPECIAL_CHARACTER_REGEX = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\|]/;
export const ALPHANUMERIC_UNDERSCORE_DASH = /^[a-zA-Z0-9 _-]+$/;
export const ALPHANUMERIC_DOT_REGEX = /^[a-zA-Z0-9 .]+$/;
export const SPACE_AND_PLUS_SYMBOL_PATTERN = /\s|\+/g;
// For bulk import email regex is different than the generic email regex
// used across the application. Reason being that we need some additional
// checks like no two consecutive special characters. This is done to prevent sql injections
export const BULK_IMPORT_EMAIL_REGEX =
  /^[A-Za-z0-9]+([._+-]?[A-Za-z0-9]+)*@[A-Za-z0-9-]+(\.[A-Za-z]{2,})+$/;
