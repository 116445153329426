export const MINUTES_COUNT_IN_ONE_HOUR = 60;
export const DEFAULT_TIME_FORMAT_12 = "hh:mm A";
export const DEFAULT_TIME_FORMAT_24 = "HH:mm";
export const DEFAULT_DATE_FORMAT = "YYYY-MM-DD";
export const DISPLAY_DATE_YEAR_TIME_FORMAT = `MMMM DD, YYYY, ${DEFAULT_TIME_FORMAT_12}`;
export const DATE_FIRST_DATE_FORMAT = `Do MMMM, YYYY`;
export const DISPLAY_TIME_FORMAT = "HH:mm:ss";
export const DISPLAY_FULL_DATE_YEAR_TIME_FORMAT = `YYYY-MM-DD ${DISPLAY_TIME_FORMAT}`;
export const DISPLAY_DATE_YEAR_FORMAT = "MMMM Do YYYY";
export const DAYS_MONTH_YEAR_FORMAT = "DD/MM/YYYY";
export const DAYS_MONTH_YEAR_TIME_FORMAT = "DD/MM/YYYY, hh:mm A";
export const DATE_YEAR_MONTH_DAY_TIME_FORMAT = "YYYY-MM-DD hh:mm A";
export const DEFAULT_TIME_FORMAT_24_WITH_SECS = "HH:mm:ss";
export const DAYS = "days";
export const WEEKS = "weeks";
export const MONTHS = "months";
export const WEEK = "week";
export const DEFAULT_DAYS_TO_ADD = 1;
