/**
 * Dict for the RTE toolbar choose name and pass to "filterToolbarGroups: string[]"
 * and passing name will be removed from the RTE toolbar
 */
export const TOOLBAR_ELEMENT_NAME = {
  font_heading: "font_heading",
  font_family: "font_family",
  font_size: "font_size",
  bold: "bold",
  italic: "italic",
  underline: "underline",
  strike_through: "strike_through",
  alignment: "alignment",
  color: "color",
  backgroung_color: "backgroung_color",
  ordered_list: "ordered_list",
  unordered_list: "unordered_list",
  indent_right: "indent_right",
  indent_left: "indent_left",
  link: "link",
  image: "image",
  table: "table",
  personalise: "personalise",
  codeToText: "codeToText",
};
