export const crmConstant = {
  filterType: {
    location: "location",
    org_member: "org_member",
    reason_type: "reason_type",
    referer_group_id: "referer_group_id",
    status: "status",
    tags: "tags",
  },
  logsType: {
    DEFAULT: 1,
    LEAD_CAPTURED: 2,
    LEAD_REASON_UPDATED: 3,
    SENT_WHATSAPP_CAMPAIGN: 4,
    SENT_EMAIL: 5,
    LEAD_STATUS_CHANGED: 6,
    LEAD_ASSIGNED_TO_MEMBER: 7,
    LEAD_ASSIGNEE_UPDATED: 8,
    TAG_ASSIGNED: 9,
    TAG_UNASSIGNED: 10,
    LEAD_CONVERTED_TO_CUSTOMER: 11,
    BOUGHT_OFFERING: 12,
    RENEWED_SUBSCRIPTION: 13,
    CANCELLED_SUBSCRIPTION: 14,
    NOTE: 15,
    PAYMENT_PENDING: 16,
    PAYMENT_FAILED: 17,
    GET_IN_TOUCH: 18,
    LISTING_GET_IN_TOUCH: 19,
    SUBSCRIBED_OFFERING: 20,
  },
};

export const ENTITY_TYPE = {
  customer: {
    value: 2,
    label: "Customer",
    key: "customer",
  },
  lead: {
    value: 1,
    label: "Lead",
    key: "lead",
  },
};
