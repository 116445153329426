import NumberQuestionTypeValidation from "features/ManageOfferings/modules/BookingQuestions/modules/QuestionTypeValidations/NumberQuestionTypeValidation/NumberQuestionTypeValidation";
import {
  CalendarQuestionTypeShowAnswer,
  DefaultQuestionTypeShowAnswer,
  FileQuestionTypeShowAnswer,
  FileQuestionTypeValidation,
  MultiSelectQuestionTypeShowAnswer,
  SelectOptionTypeValidation,
  SingleSelectQuestionTypeShowAnswer,
  TextQuestionTypeValidation,
} from "../schedule-v2/commonPages/Components/QuestionCards/QuestionTypeComponents";
import React from "react";

export const customer_questions_constants = {
  customer_schedule_status: {
    action_required: 1,
    scheduled: 2,
    completed: 3,
  },

  avaibility_type: {
    occur_on_selected_days_of_week: 6,
    occur_on_selected_dates: 5,
    not_decided: 4,
    custom: 3,
    weekday: 2,
    single_day: 1,
    everyday: 0,
  },
};

export const question_type_id = {
  PARAGRAPH_TEXT: 1,
  SINGLE_SELECT_DROPDOWN: 2,
  MULTI_SELECT_DROPDOWN: 3,
  CALENDAR: 4,
  FILE: 5,
  SHORT_TEXT: 9,
  NUMBER: 10,
  URL: 11,
  GENDER: 12,
  INDIAN_STATE: 13,
  INDIAN_CITY: 14,
};

export const questions_type_mapping = {
  [question_type_id.PARAGRAPH_TEXT]: {
    id: 1,
    value: 1,
    label: "Paragraph Text",
    typeSpecificValidation: () => <TextQuestionTypeValidation />,
    showAnswer: (answer) => <DefaultQuestionTypeShowAnswer answer={answer} />,
  },
  [question_type_id.SINGLE_SELECT_DROPDOWN]: {
    id: 2,
    value: 2,
    label: "Select One Option",
    typeSpecificValidation: (question) => (
      <SelectOptionTypeValidation question={question} />
    ),
    hasChoices: true,
    tooltip:
      "These options will show up as  a dropdown to your customers  while answering this question",
    showAnswer: (answer) => (
      <SingleSelectQuestionTypeShowAnswer answer={answer} />
    ),
  },
  [question_type_id.MULTI_SELECT_DROPDOWN]: {
    id: 3,
    value: 3,
    label: "Select Multiple Options",
    typeSpecificValidation: (question) => (
      <SelectOptionTypeValidation question={question} />
    ),
    hasChoices: true,
    tooltip: "Here customers will have to tick possible options",
    showAnswer: (answer) => (
      <MultiSelectQuestionTypeShowAnswer answer={answer} />
    ),
  },
  [question_type_id.CALENDAR]: {
    id: 4,
    value: 4,
    label: "Calendar",
    showAnswer: (answer) => <CalendarQuestionTypeShowAnswer answer={answer} />,
  },
  [question_type_id.FILE]: {
    id: 5,
    value: 5,
    label: "File",
    typeSpecificValidation: () => <FileQuestionTypeValidation />,
    showAnswer: (answer) => <FileQuestionTypeShowAnswer answer={answer} />,
  },
  [question_type_id.SHORT_TEXT]: {
    id: 6,
    value: 9,
    label: "Short Text Question",
    typeSpecificValidation: (question) => (
      <TextQuestionTypeValidation question={question} />
    ),
    showAnswer: (answer) => <DefaultQuestionTypeShowAnswer answer={answer} />,
  },
  [question_type_id.NUMBER]: {
    id: 7,
    value: 10,
    label: "Number",
    typeSpecificValidation: () => <NumberQuestionTypeValidation />,
    showAnswer: (answer) => <DefaultQuestionTypeShowAnswer answer={answer} />,
  },
  [question_type_id.URL]: {
    id: 8,
    value: 11,
    label: "URL",
    showAnswer: (answer) => <DefaultQuestionTypeShowAnswer answer={answer} />,
  },
  [question_type_id.GENDER]: {
    id: 9,
    value: 12,
    label: "Gender",
    showAnswer: (answer) => <DefaultQuestionTypeShowAnswer answer={answer} />,
  },
  [question_type_id.INDIAN_STATE]: {
    id: 10,
    value: 13,
    label: "Indian State",
    showAnswer: (answer) => <DefaultQuestionTypeShowAnswer answer={answer} />,
  },
  [question_type_id.INDIAN_CITY]: {
    id: 11,
    value: 14,
    label: "Indian City",
    showAnswer: (answer) => <DefaultQuestionTypeShowAnswer answer={answer} />,
  },
};

export const questions_placement_mapping = {
  before_booking: 1,
  after_booking: 2,
};
