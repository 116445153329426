export const upsell_constants = {
  upsell_type: {
    ORDER_BUMPS: 1,
    THANK_YOU_PAGE: 2,
    UPSELL: 3,
    BRANDED_COMMUNITY: 4,
  },
  upsell_status_choices: {
    active: 1,
    inActive: 2,
  },
  entity_change_state: {
    NO_CHANGE: 0,
    UPDATED: 1,
    NEW_ADDED: 2,
    DELETED: 3,
  },
};

export const upsellTypeOptions = [
  {
    label: "Order Bumps",
    id: upsell_constants.upsell_type.ORDER_BUMPS,
    value: String(upsell_constants.upsell_type.ORDER_BUMPS),
  },
  // will need this
  // { label: "Thank you page", id: upsell_constants.upsell_type.THANK_YOU_PAGE, value: String(upsell_constants.upsell_type.THANK_YOU_PAGE) },
  {
    label: "Upsell",
    id: upsell_constants.upsell_type.UPSELL,
    value: String(upsell_constants.upsell_type.UPSELL),
  },
  {
    label: "Branded Community",
    id: upsell_constants.upsell_type.BRANDED_COMMUNITY,
    value: String(upsell_constants.upsell_type.BRANDED_COMMUNITY),
  },
];
