const local_storage_keys = {
  IS_FEATURE_ONBOARDED: "is_feature_onboarded",
  ORG_UUID: "org_uuid",
  /** These keys (ENTITY_TYPE,CUSTOMER,GUEST_USER_NAME) are employed to store values that can be extracted for API calls on the details page, triggered when clicking to view details in the "myBookings" and "customers" tables.
   * Additionally, they are utilized in webpage leads,TODO @DEV please mention the exact use case here when identified. */
  ENTITY_TYPE: "entity_type", // this key is used to store entity type e.g customers/leads
  CUSTOMER: "customer", // this key is used to store customer details
  GUEST_USER_NAME: "guest__username", // this key is used to store customer username
};

export default local_storage_keys;
