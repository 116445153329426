import { timezone_constants } from "./constants";

export const ASIA_KOLKATA_TIMEZONE = {
  country_code: "IN",
  offset: "+0530",
  value: "Asia/Kolkata",
  label: "Asia/Kolkata (GMT +0530)",
};

const timezones = {
  all_timezones: [
    {
      country_code: "CI",
      offset: "+0000",
      value: "Africa/Abidjan",
      label: "Africa/Abidjan (GMT +0000)",
    },
    {
      country_code: "GH",
      offset: "+0000",
      value: "Africa/Accra",
      label: "Africa/Accra (GMT +0000)",
    },
    {
      country_code: "ET",
      offset: "+0300",
      value: "Africa/Addis_Ababa",
      label: "Africa/Addis_Ababa (GMT +0300)",
    },
    {
      country_code: "DZ",
      offset: "+0100",
      value: "Africa/Algiers",
      label: "Africa/Algiers (GMT +0100)",
    },
    {
      country_code: "ER",
      offset: "+0300",
      value: "Africa/Asmara",
      label: "Africa/Asmara (GMT +0300)",
    },
    {
      country_code: "ML",
      offset: "+0000",
      value: "Africa/Bamako",
      label: "Africa/Bamako (GMT +0000)",
    },
    {
      country_code: "CF",
      offset: "+0100",
      value: "Africa/Bangui",
      label: "Africa/Bangui (GMT +0100)",
    },
    {
      country_code: "GM",
      offset: "+0000",
      value: "Africa/Banjul",
      label: "Africa/Banjul (GMT +0000)",
    },
    {
      country_code: "GW",
      offset: "+0000",
      value: "Africa/Bissau",
      label: "Africa/Bissau (GMT +0000)",
    },
    {
      country_code: "MW",
      offset: "+0200",
      value: "Africa/Blantyre",
      label: "Africa/Blantyre (GMT +0200)",
    },
    {
      country_code: "CG",
      offset: "+0100",
      value: "Africa/Brazzaville",
      label: "Africa/Brazzaville (GMT +0100)",
    },
    {
      country_code: "BI",
      offset: "+0200",
      value: "Africa/Bujumbura",
      label: "Africa/Bujumbura (GMT +0200)",
    },
    {
      country_code: "EG",
      offset: "+0200",
      value: "Africa/Cairo",
      label: "Africa/Cairo (GMT +0200)",
    },
    {
      country_code: "MA",
      offset: "+0100",
      value: "Africa/Casablanca",
      label: "Africa/Casablanca (GMT +0100)",
    },
    {
      country_code: "ES",
      offset: "+0100",
      value: "Africa/Ceuta",
      label: "Africa/Ceuta (GMT +0100)",
    },
    {
      country_code: "GN",
      offset: "+0000",
      value: "Africa/Conakry",
      label: "Africa/Conakry (GMT +0000)",
    },
    {
      country_code: "SN",
      offset: "+0000",
      value: "Africa/Dakar",
      label: "Africa/Dakar (GMT +0000)",
    },
    {
      country_code: "TZ",
      offset: "+0300",
      value: "Africa/Dar_es_Salaam",
      label: "Africa/Dar_es_Salaam (GMT +0300)",
    },
    {
      country_code: "DJ",
      offset: "+0300",
      value: "Africa/Djibouti",
      label: "Africa/Djibouti (GMT +0300)",
    },
    {
      country_code: "CM",
      offset: "+0100",
      value: "Africa/Douala",
      label: "Africa/Douala (GMT +0100)",
    },
    {
      country_code: "EH",
      offset: "+0100",
      value: "Africa/El_Aaiun",
      label: "Africa/El_Aaiun (GMT +0100)",
    },
    {
      country_code: "SL",
      offset: "+0000",
      value: "Africa/Freetown",
      label: "Africa/Freetown (GMT +0000)",
    },
    {
      country_code: "BW",
      offset: "+0200",
      value: "Africa/Gaborone",
      label: "Africa/Gaborone (GMT +0200)",
    },
    {
      country_code: "ZW",
      offset: "+0200",
      value: "Africa/Harare",
      label: "Africa/Harare (GMT +0200)",
    },
    {
      country_code: "ZA",
      offset: "+0200",
      value: "Africa/Johannesburg",
      label: "Africa/Johannesburg (GMT +0200)",
    },
    {
      country_code: "SS",
      offset: "+0200",
      value: "Africa/Juba",
      label: "Africa/Juba (GMT +0200)",
    },
    {
      country_code: "UG",
      offset: "+0300",
      value: "Africa/Kampala",
      label: "Africa/Kampala (GMT +0300)",
    },
    {
      country_code: "SD",
      offset: "+0200",
      value: "Africa/Khartoum",
      label: "Africa/Khartoum (GMT +0200)",
    },
    {
      country_code: "RW",
      offset: "+0200",
      value: "Africa/Kigali",
      label: "Africa/Kigali (GMT +0200)",
    },
    {
      country_code: "CD",
      offset: "+0100",
      value: "Africa/Kinshasa",
      label: "Africa/Kinshasa (GMT +0100)",
    },
    {
      country_code: "NG",
      offset: "+0100",
      value: "Africa/Lagos",
      label: "Africa/Lagos (GMT +0100)",
    },
    {
      country_code: "GA",
      offset: "+0100",
      value: "Africa/Libreville",
      label: "Africa/Libreville (GMT +0100)",
    },
    {
      country_code: "TG",
      offset: "+0000",
      value: "Africa/Lome",
      label: "Africa/Lome (GMT +0000)",
    },
    {
      country_code: "AO",
      offset: "+0100",
      value: "Africa/Luanda",
      label: "Africa/Luanda (GMT +0100)",
    },
    {
      country_code: "CD",
      offset: "+0200",
      value: "Africa/Lubumbashi",
      label: "Africa/Lubumbashi (GMT +0200)",
    },
    {
      country_code: "ZM",
      offset: "+0200",
      value: "Africa/Lusaka",
      label: "Africa/Lusaka (GMT +0200)",
    },
    {
      country_code: "GQ",
      offset: "+0100",
      value: "Africa/Malabo",
      label: "Africa/Malabo (GMT +0100)",
    },
    {
      country_code: "MZ",
      offset: "+0200",
      value: "Africa/Maputo",
      label: "Africa/Maputo (GMT +0200)",
    },
    {
      country_code: "LS",
      offset: "+0200",
      value: "Africa/Maseru",
      label: "Africa/Maseru (GMT +0200)",
    },
    {
      country_code: "SZ",
      offset: "+0200",
      value: "Africa/Mbabane",
      label: "Africa/Mbabane (GMT +0200)",
    },
    {
      country_code: "SO",
      offset: "+0300",
      value: "Africa/Mogadishu",
      label: "Africa/Mogadishu (GMT +0300)",
    },
    {
      country_code: "LR",
      offset: "+0000",
      value: "Africa/Monrovia",
      label: "Africa/Monrovia (GMT +0000)",
    },
    {
      country_code: "KE",
      offset: "+0300",
      value: "Africa/Nairobi",
      label: "Africa/Nairobi (GMT +0300)",
    },
    {
      country_code: "TD",
      offset: "+0100",
      value: "Africa/Ndjamena",
      label: "Africa/Ndjamena (GMT +0100)",
    },
    {
      country_code: "NE",
      offset: "+0100",
      value: "Africa/Niamey",
      label: "Africa/Niamey (GMT +0100)",
    },
    {
      country_code: "MR",
      offset: "+0000",
      value: "Africa/Nouakchott",
      label: "Africa/Nouakchott (GMT +0000)",
    },
    {
      country_code: "BF",
      offset: "+0000",
      value: "Africa/Ouagadougou",
      label: "Africa/Ouagadougou (GMT +0000)",
    },
    {
      country_code: "BJ",
      offset: "+0100",
      value: "Africa/Porto-Novo",
      label: "Africa/Porto-Novo (GMT +0100)",
    },
    {
      country_code: "ST",
      offset: "+0000",
      value: "Africa/Sao_Tome",
      label: "Africa/Sao_Tome (GMT +0000)",
    },
    {
      country_code: "LY",
      offset: "+0200",
      value: "Africa/Tripoli",
      label: "Africa/Tripoli (GMT +0200)",
    },
    {
      country_code: "TN",
      offset: "+0100",
      value: "Africa/Tunis",
      label: "Africa/Tunis (GMT +0100)",
    },
    {
      country_code: "NA",
      offset: "+0200",
      value: "Africa/Windhoek",
      label: "Africa/Windhoek (GMT +0200)",
    },
    {
      country_code: "US",
      offset: "-1000",
      value: "America/Adak",
      label: "America/Adak (GMT -1000)",
    },
    {
      country_code: "US",
      offset: "-0900",
      value: "America/Anchorage",
      label: "America/Anchorage (GMT -0900)",
    },
    {
      country_code: "AI",
      offset: "-0400",
      value: "America/Anguilla",
      label: "America/Anguilla (GMT -0400)",
    },
    {
      country_code: "AG",
      offset: "-0400",
      value: "America/Antigua",
      label: "America/Antigua (GMT -0400)",
    },
    {
      country_code: "BR",
      offset: "-0300",
      value: "America/Araguaina",
      label: "America/Araguaina (GMT -0300)",
    },
    {
      country_code: "AR",
      offset: "-0300",
      value: "America/Argentina/Buenos_Aires",
      label: "America/Argentina/Buenos_Aires (GMT -0300)",
    },
    {
      country_code: "AR",
      offset: "-0300",
      value: "America/Argentina/Catamarca",
      label: "America/Argentina/Catamarca (GMT -0300)",
    },
    {
      country_code: "AR",
      offset: "-0300",
      value: "America/Argentina/Cordoba",
      label: "America/Argentina/Cordoba (GMT -0300)",
    },
    {
      country_code: "AR",
      offset: "-0300",
      value: "America/Argentina/Jujuy",
      label: "America/Argentina/Jujuy (GMT -0300)",
    },
    {
      country_code: "AR",
      offset: "-0300",
      value: "America/Argentina/La_Rioja",
      label: "America/Argentina/La_Rioja (GMT -0300)",
    },
    {
      country_code: "AR",
      offset: "-0300",
      value: "America/Argentina/Mendoza",
      label: "America/Argentina/Mendoza (GMT -0300)",
    },
    {
      country_code: "AR",
      offset: "-0300",
      value: "America/Argentina/Rio_Gallegos",
      label: "America/Argentina/Rio_Gallegos (GMT -0300)",
    },
    {
      country_code: "AR",
      offset: "-0300",
      value: "America/Argentina/Salta",
      label: "America/Argentina/Salta (GMT -0300)",
    },
    {
      country_code: "AR",
      offset: "-0300",
      value: "America/Argentina/San_Juan",
      label: "America/Argentina/San_Juan (GMT -0300)",
    },
    {
      country_code: "AR",
      offset: "-0300",
      value: "America/Argentina/San_Luis",
      label: "America/Argentina/San_Luis (GMT -0300)",
    },
    {
      country_code: "AR",
      offset: "-0300",
      value: "America/Argentina/Tucuman",
      label: "America/Argentina/Tucuman (GMT -0300)",
    },
    {
      country_code: "AR",
      offset: "-0300",
      value: "America/Argentina/Ushuaia",
      label: "America/Argentina/Ushuaia (GMT -0300)",
    },
    {
      country_code: "AW",
      offset: "-0400",
      value: "America/Aruba",
      label: "America/Aruba (GMT -0400)",
    },
    {
      country_code: "PY",
      offset: "-0300",
      value: "America/Asuncion",
      label: "America/Asuncion (GMT -0300)",
    },
    {
      country_code: "CA",
      offset: "-0500",
      value: "America/Atikokan",
      label: "America/Atikokan (GMT -0500)",
    },
    {
      country_code: "BR",
      offset: "-0300",
      value: "America/Bahia",
      label: "America/Bahia (GMT -0300)",
    },
    {
      country_code: "MX",
      offset: "-0600",
      value: "America/Bahia_Banderas",
      label: "America/Bahia_Banderas (GMT -0600)",
    },
    {
      country_code: "BB",
      offset: "-0400",
      value: "America/Barbados",
      label: "America/Barbados (GMT -0400)",
    },
    {
      country_code: "BR",
      offset: "-0300",
      value: "America/Belem",
      label: "America/Belem (GMT -0300)",
    },
    {
      country_code: "BZ",
      offset: "-0600",
      value: "America/Belize",
      label: "America/Belize (GMT -0600)",
    },
    {
      country_code: "CA",
      offset: "-0400",
      value: "America/Blanc-Sablon",
      label: "America/Blanc-Sablon (GMT -0400)",
    },
    {
      country_code: "BR",
      offset: "-0400",
      value: "America/Boa_Vista",
      label: "America/Boa_Vista (GMT -0400)",
    },
    {
      country_code: "CO",
      offset: "-0500",
      value: "America/Bogota",
      label: "America/Bogota (GMT -0500)",
    },
    {
      country_code: "US",
      offset: "-0700",
      value: "America/Boise",
      label: "America/Boise (GMT -0700)",
    },
    {
      country_code: "CA",
      offset: "-0700",
      value: "America/Cambridge_Bay",
      label: "America/Cambridge_Bay (GMT -0700)",
    },
    {
      country_code: "BR",
      offset: "-0400",
      value: "America/Campo_Grande",
      label: "America/Campo_Grande (GMT -0400)",
    },
    {
      country_code: "MX",
      offset: "-0500",
      value: "America/Cancun",
      label: "America/Cancun (GMT -0500)",
    },
    {
      country_code: "VE",
      offset: "-0400",
      value: "America/Caracas",
      label: "America/Caracas (GMT -0400)",
    },
    {
      country_code: "GF",
      offset: "-0300",
      value: "America/Cayenne",
      label: "America/Cayenne (GMT -0300)",
    },
    {
      country_code: "KY",
      offset: "-0500",
      value: "America/Cayman",
      label: "America/Cayman (GMT -0500)",
    },
    {
      country_code: "US",
      offset: "-0600",
      value: "America/Chicago",
      label: "America/Chicago (GMT -0600)",
    },
    {
      country_code: "MX",
      offset: "-0700",
      value: "America/Chihuahua",
      label: "America/Chihuahua (GMT -0700)",
    },
    {
      country_code: "CR",
      offset: "-0600",
      value: "America/Costa_Rica",
      label: "America/Costa_Rica (GMT -0600)",
    },
    {
      country_code: "CA",
      offset: "-0700",
      value: "America/Creston",
      label: "America/Creston (GMT -0700)",
    },
    {
      country_code: "BR",
      offset: "-0400",
      value: "America/Cuiaba",
      label: "America/Cuiaba (GMT -0400)",
    },
    {
      country_code: "CW",
      offset: "-0400",
      value: "America/Curacao",
      label: "America/Curacao (GMT -0400)",
    },
    {
      country_code: "GL",
      offset: "+0000",
      value: "America/Danmarkshavn",
      label: "America/Danmarkshavn (GMT +0000)",
    },
    {
      country_code: "CA",
      offset: "-0700",
      value: "America/Dawson",
      label: "America/Dawson (GMT -0700)",
    },
    {
      country_code: "CA",
      offset: "-0700",
      value: "America/Dawson_Creek",
      label: "America/Dawson_Creek (GMT -0700)",
    },
    {
      country_code: "US",
      offset: "-0700",
      value: "America/Denver",
      label: "America/Denver (GMT -0700)",
    },
    {
      country_code: "US",
      offset: "-0500",
      value: "America/Detroit",
      label: "America/Detroit (GMT -0500)",
    },
    {
      country_code: "DM",
      offset: "-0400",
      value: "America/Dominica",
      label: "America/Dominica (GMT -0400)",
    },
    {
      country_code: "CA",
      offset: "-0700",
      value: "America/Edmonton",
      label: "America/Edmonton (GMT -0700)",
    },
    {
      country_code: "BR",
      offset: "-0500",
      value: "America/Eirunepe",
      label: "America/Eirunepe (GMT -0500)",
    },
    {
      country_code: "SV",
      offset: "-0600",
      value: "America/El_Salvador",
      label: "America/El_Salvador (GMT -0600)",
    },
    {
      country_code: "CA",
      offset: "-0700",
      value: "America/Fort_Nelson",
      label: "America/Fort_Nelson (GMT -0700)",
    },
    {
      country_code: "BR",
      offset: "-0300",
      value: "America/Fortaleza",
      label: "America/Fortaleza (GMT -0300)",
    },
    {
      country_code: "CA",
      offset: "-0400",
      value: "America/Glace_Bay",
      label: "America/Glace_Bay (GMT -0400)",
    },
    {
      country_code: "CA",
      offset: "-0400",
      value: "America/Goose_Bay",
      label: "America/Goose_Bay (GMT -0400)",
    },
    {
      country_code: "TC",
      offset: "-0500",
      value: "America/Grand_Turk",
      label: "America/Grand_Turk (GMT -0500)",
    },
    {
      country_code: "GD",
      offset: "-0400",
      value: "America/Grenada",
      label: "America/Grenada (GMT -0400)",
    },
    {
      country_code: "GP",
      offset: "-0400",
      value: "America/Guadeloupe",
      label: "America/Guadeloupe (GMT -0400)",
    },
    {
      country_code: "GT",
      offset: "-0600",
      value: "America/Guatemala",
      label: "America/Guatemala (GMT -0600)",
    },
    {
      country_code: "EC",
      offset: "-0500",
      value: "America/Guayaquil",
      label: "America/Guayaquil (GMT -0500)",
    },
    {
      country_code: "GY",
      offset: "-0400",
      value: "America/Guyana",
      label: "America/Guyana (GMT -0400)",
    },
    {
      country_code: "CA",
      offset: "-0400",
      value: "America/Halifax",
      label: "America/Halifax (GMT -0400)",
    },
    {
      country_code: "CU",
      offset: "-0500",
      value: "America/Havana",
      label: "America/Havana (GMT -0500)",
    },
    {
      country_code: "MX",
      offset: "-0700",
      value: "America/Hermosillo",
      label: "America/Hermosillo (GMT -0700)",
    },
    {
      country_code: "US",
      offset: "-0500",
      value: "America/Indiana/Indianapolis",
      label: "America/Indiana/Indianapolis (GMT -0500)",
    },
    {
      country_code: "US",
      offset: "-0600",
      value: "America/Indiana/Knox",
      label: "America/Indiana/Knox (GMT -0600)",
    },
    {
      country_code: "US",
      offset: "-0500",
      value: "America/Indiana/Marengo",
      label: "America/Indiana/Marengo (GMT -0500)",
    },
    {
      country_code: "US",
      offset: "-0500",
      value: "America/Indiana/Petersburg",
      label: "America/Indiana/Petersburg (GMT -0500)",
    },
    {
      country_code: "US",
      offset: "-0600",
      value: "America/Indiana/Tell_City",
      label: "America/Indiana/Tell_City (GMT -0600)",
    },
    {
      country_code: "US",
      offset: "-0500",
      value: "America/Indiana/Vevay",
      label: "America/Indiana/Vevay (GMT -0500)",
    },
    {
      country_code: "US",
      offset: "-0500",
      value: "America/Indiana/Vincennes",
      label: "America/Indiana/Vincennes (GMT -0500)",
    },
    {
      country_code: "US",
      offset: "-0500",
      value: "America/Indiana/Winamac",
      label: "America/Indiana/Winamac (GMT -0500)",
    },
    {
      country_code: "CA",
      offset: "-0700",
      value: "America/Inuvik",
      label: "America/Inuvik (GMT -0700)",
    },
    {
      country_code: "CA",
      offset: "-0500",
      value: "America/Iqaluit",
      label: "America/Iqaluit (GMT -0500)",
    },
    {
      country_code: "JM",
      offset: "-0500",
      value: "America/Jamaica",
      label: "America/Jamaica (GMT -0500)",
    },
    {
      country_code: "US",
      offset: "-0900",
      value: "America/Juneau",
      label: "America/Juneau (GMT -0900)",
    },
    {
      country_code: "US",
      offset: "-0500",
      value: "America/Kentucky/Louisville",
      label: "America/Kentucky/Louisville (GMT -0500)",
    },
    {
      country_code: "US",
      offset: "-0500",
      value: "America/Kentucky/Monticello",
      label: "America/Kentucky/Monticello (GMT -0500)",
    },
    {
      country_code: "BQ",
      offset: "-0400",
      value: "America/Kralendijk",
      label: "America/Kralendijk (GMT -0400)",
    },
    {
      country_code: "BO",
      offset: "-0400",
      value: "America/La_Paz",
      label: "America/La_Paz (GMT -0400)",
    },
    {
      country_code: "PE",
      offset: "-0500",
      value: "America/Lima",
      label: "America/Lima (GMT -0500)",
    },
    { ...timezone_constants.default_internation_timezone },
    {
      country_code: "SX",
      offset: "-0400",
      value: "America/Lower_Princes",
      label: "America/Lower_Princes (GMT -0400)",
    },
    {
      country_code: "BR",
      offset: "-0300",
      value: "America/Maceio",
      label: "America/Maceio (GMT -0300)",
    },
    {
      country_code: "NI",
      offset: "-0600",
      value: "America/Managua",
      label: "America/Managua (GMT -0600)",
    },
    {
      country_code: "BR",
      offset: "-0400",
      value: "America/Manaus",
      label: "America/Manaus (GMT -0400)",
    },
    {
      country_code: "MF",
      offset: "-0400",
      value: "America/Marigot",
      label: "America/Marigot (GMT -0400)",
    },
    {
      country_code: "MQ",
      offset: "-0400",
      value: "America/Martinique",
      label: "America/Martinique (GMT -0400)",
    },
    {
      country_code: "MX",
      offset: "-0600",
      value: "America/Matamoros",
      label: "America/Matamoros (GMT -0600)",
    },
    {
      country_code: "MX",
      offset: "-0700",
      value: "America/Mazatlan",
      label: "America/Mazatlan (GMT -0700)",
    },
    {
      country_code: "US",
      offset: "-0600",
      value: "America/Menominee",
      label: "America/Menominee (GMT -0600)",
    },
    {
      country_code: "MX",
      offset: "-0600",
      value: "America/Merida",
      label: "America/Merida (GMT -0600)",
    },
    {
      country_code: "US",
      offset: "-0900",
      value: "America/Metlakatla",
      label: "America/Metlakatla (GMT -0900)",
    },
    {
      country_code: "MX",
      offset: "-0600",
      value: "America/Mexico_City",
      label: "America/Mexico_City (GMT -0600)",
    },
    {
      country_code: "PM",
      offset: "-0300",
      value: "America/Miquelon",
      label: "America/Miquelon (GMT -0300)",
    },
    {
      country_code: "CA",
      offset: "-0400",
      value: "America/Moncton",
      label: "America/Moncton (GMT -0400)",
    },
    {
      country_code: "MX",
      offset: "-0600",
      value: "America/Monterrey",
      label: "America/Monterrey (GMT -0600)",
    },
    {
      country_code: "UY",
      offset: "-0300",
      value: "America/Montevideo",
      label: "America/Montevideo (GMT -0300)",
    },
    {
      country_code: "MS",
      offset: "-0400",
      value: "America/Montserrat",
      label: "America/Montserrat (GMT -0400)",
    },
    {
      country_code: "BS",
      offset: "-0500",
      value: "America/Nassau",
      label: "America/Nassau (GMT -0500)",
    },
    {
      country_code: "US",
      offset: "-0500",
      value: "America/New_York",
      label: "America/New_York (GMT -0500)",
    },
    {
      country_code: "CA",
      offset: "-0500",
      value: "America/Nipigon",
      label: "America/Nipigon (GMT -0500)",
    },
    {
      country_code: "US",
      offset: "-0900",
      value: "America/Nome",
      label: "America/Nome (GMT -0900)",
    },
    {
      country_code: "BR",
      offset: "-0200",
      value: "America/Noronha",
      label: "America/Noronha (GMT -0200)",
    },
    {
      country_code: "US",
      offset: "-0600",
      value: "America/North_Dakota/Beulah",
      label: "America/North_Dakota/Beulah (GMT -0600)",
    },
    {
      country_code: "US",
      offset: "-0600",
      value: "America/North_Dakota/Center",
      label: "America/North_Dakota/Center (GMT -0600)",
    },
    {
      country_code: "US",
      offset: "-0600",
      value: "America/North_Dakota/New_Salem",
      label: "America/North_Dakota/New_Salem (GMT -0600)",
    },
    {
      country_code: "GL",
      offset: "-0300",
      value: "America/Nuuk",
      label: "America/Nuuk (GMT -0300)",
    },
    {
      country_code: "MX",
      offset: "-0700",
      value: "America/Ojinaga",
      label: "America/Ojinaga (GMT -0700)",
    },
    {
      country_code: "PA",
      offset: "-0500",
      value: "America/Panama",
      label: "America/Panama (GMT -0500)",
    },
    {
      country_code: "CA",
      offset: "-0500",
      value: "America/Pangnirtung",
      label: "America/Pangnirtung (GMT -0500)",
    },
    {
      country_code: "SR",
      offset: "-0300",
      value: "America/Paramaribo",
      label: "America/Paramaribo (GMT -0300)",
    },
    {
      country_code: "US",
      offset: "-0700",
      value: "America/Phoenix",
      label: "America/Phoenix (GMT -0700)",
    },
    {
      country_code: "HT",
      offset: "-0500",
      value: "America/Port-au-Prince",
      label: "America/Port-au-Prince (GMT -0500)",
    },
    {
      country_code: "TT",
      offset: "-0400",
      value: "America/Port_of_Spain",
      label: "America/Port_of_Spain (GMT -0400)",
    },
    {
      country_code: "BR",
      offset: "-0400",
      value: "America/Porto_Velho",
      label: "America/Porto_Velho (GMT -0400)",
    },
    {
      country_code: "PR",
      offset: "-0400",
      value: "America/Puerto_Rico",
      label: "America/Puerto_Rico (GMT -0400)",
    },
    {
      country_code: "CL",
      offset: "-0300",
      value: "America/Punta_Arenas",
      label: "America/Punta_Arenas (GMT -0300)",
    },
    {
      country_code: "CA",
      offset: "-0600",
      value: "America/Rainy_River",
      label: "America/Rainy_River (GMT -0600)",
    },
    {
      country_code: "CA",
      offset: "-0600",
      value: "America/Rankin_Inlet",
      label: "America/Rankin_Inlet (GMT -0600)",
    },
    {
      country_code: "BR",
      offset: "-0300",
      value: "America/Recife",
      label: "America/Recife (GMT -0300)",
    },
    {
      country_code: "CA",
      offset: "-0600",
      value: "America/Regina",
      label: "America/Regina (GMT -0600)",
    },
    {
      country_code: "CA",
      offset: "-0600",
      value: "America/Resolute",
      label: "America/Resolute (GMT -0600)",
    },
    {
      country_code: "BR",
      offset: "-0500",
      value: "America/Rio_Branco",
      label: "America/Rio_Branco (GMT -0500)",
    },
    {
      country_code: "BR",
      offset: "-0300",
      value: "America/Santarem",
      label: "America/Santarem (GMT -0300)",
    },
    {
      country_code: "CL",
      offset: "-0300",
      value: "America/Santiago",
      label: "America/Santiago (GMT -0300)",
    },
    {
      country_code: "DO",
      offset: "-0400",
      value: "America/Santo_Domingo",
      label: "America/Santo_Domingo (GMT -0400)",
    },
    {
      country_code: "BR",
      offset: "-0300",
      value: "America/Sao_Paulo",
      label: "America/Sao_Paulo (GMT -0300)",
    },
    {
      country_code: "GL",
      offset: "-0100",
      value: "America/Scoresbysund",
      label: "America/Scoresbysund (GMT -0100)",
    },
    {
      country_code: "US",
      offset: "-0900",
      value: "America/Sitka",
      label: "America/Sitka (GMT -0900)",
    },
    {
      country_code: "BL",
      offset: "-0400",
      value: "America/St_Barthelemy",
      label: "America/St_Barthelemy (GMT -0400)",
    },
    {
      country_code: "CA",
      offset: "-0330",
      value: "America/St_Johns",
      label: "America/St_Johns (GMT -0330)",
    },
    {
      country_code: "KN",
      offset: "-0400",
      value: "America/St_Kitts",
      label: "America/St_Kitts (GMT -0400)",
    },
    {
      country_code: "LC",
      offset: "-0400",
      value: "America/St_Lucia",
      label: "America/St_Lucia (GMT -0400)",
    },
    {
      country_code: "VI",
      offset: "-0400",
      value: "America/St_Thomas",
      label: "America/St_Thomas (GMT -0400)",
    },
    {
      country_code: "VC",
      offset: "-0400",
      value: "America/St_Vincent",
      label: "America/St_Vincent (GMT -0400)",
    },
    {
      country_code: "CA",
      offset: "-0600",
      value: "America/Swift_Current",
      label: "America/Swift_Current (GMT -0600)",
    },
    {
      country_code: "HN",
      offset: "-0600",
      value: "America/Tegucigalpa",
      label: "America/Tegucigalpa (GMT -0600)",
    },
    {
      country_code: "GL",
      offset: "-0400",
      value: "America/Thule",
      label: "America/Thule (GMT -0400)",
    },
    {
      country_code: "CA",
      offset: "-0500",
      value: "America/Thunder_Bay",
      label: "America/Thunder_Bay (GMT -0500)",
    },
    {
      country_code: "MX",
      offset: "-0800",
      value: "America/Tijuana",
      label: "America/Tijuana (GMT -0800)",
    },
    {
      country_code: "CA",
      offset: "-0500",
      value: "America/Toronto",
      label: "America/Toronto (GMT -0500)",
    },
    {
      country_code: "VG",
      offset: "-0400",
      value: "America/Tortola",
      label: "America/Tortola (GMT -0400)",
    },
    {
      country_code: "CA",
      offset: "-0800",
      value: "America/Vancouver",
      label: "America/Vancouver (GMT -0800)",
    },
    {
      country_code: "CA",
      offset: "-0700",
      value: "America/Whitehorse",
      label: "America/Whitehorse (GMT -0700)",
    },
    {
      country_code: "CA",
      offset: "-0600",
      value: "America/Winnipeg",
      label: "America/Winnipeg (GMT -0600)",
    },
    {
      country_code: "US",
      offset: "-0900",
      value: "America/Yakutat",
      label: "America/Yakutat (GMT -0900)",
    },
    {
      country_code: "CA",
      offset: "-0700",
      value: "America/Yellowknife",
      label: "America/Yellowknife (GMT -0700)",
    },
    {
      country_code: "AQ",
      offset: "+1100",
      value: "Antarctica/Casey",
      label: "Antarctica/Casey (GMT +1100)",
    },
    {
      country_code: "AQ",
      offset: "+0700",
      value: "Antarctica/Davis",
      label: "Antarctica/Davis (GMT +0700)",
    },
    {
      country_code: "AQ",
      offset: "+1000",
      value: "Antarctica/DumontDUrville",
      label: "Antarctica/DumontDUrville (GMT +1000)",
    },
    {
      country_code: "AU",
      offset: "+1100",
      value: "Antarctica/Macquarie",
      label: "Antarctica/Macquarie (GMT +1100)",
    },
    {
      country_code: "AQ",
      offset: "+0500",
      value: "Antarctica/Mawson",
      label: "Antarctica/Mawson (GMT +0500)",
    },
    {
      country_code: "AQ",
      offset: "+1300",
      value: "Antarctica/McMurdo",
      label: "Antarctica/McMurdo (GMT +1300)",
    },
    {
      country_code: "AQ",
      offset: "-0300",
      value: "Antarctica/Palmer",
      label: "Antarctica/Palmer (GMT -0300)",
    },
    {
      country_code: "AQ",
      offset: "-0300",
      value: "Antarctica/Rothera",
      label: "Antarctica/Rothera (GMT -0300)",
    },
    {
      country_code: "AQ",
      offset: "+0300",
      value: "Antarctica/Syowa",
      label: "Antarctica/Syowa (GMT +0300)",
    },
    {
      country_code: "AQ",
      offset: "+0000",
      value: "Antarctica/Troll",
      label: "Antarctica/Troll (GMT +0000)",
    },
    {
      country_code: "AQ",
      offset: "+0600",
      value: "Antarctica/Vostok",
      label: "Antarctica/Vostok (GMT +0600)",
    },
    {
      country_code: "SJ",
      offset: "+0100",
      value: "Arctic/Longyearbyen",
      label: "Arctic/Longyearbyen (GMT +0100)",
    },
    {
      country_code: "YE",
      offset: "+0300",
      value: "Asia/Aden",
      label: "Asia/Aden (GMT +0300)",
    },
    {
      country_code: "KZ",
      offset: "+0600",
      value: "Asia/Almaty",
      label: "Asia/Almaty (GMT +0600)",
    },
    {
      country_code: "JO",
      offset: "+0200",
      value: "Asia/Amman",
      label: "Asia/Amman (GMT +0200)",
    },
    {
      country_code: "RU",
      offset: "+1200",
      value: "Asia/Anadyr",
      label: "Asia/Anadyr (GMT +1200)",
    },
    {
      country_code: "KZ",
      offset: "+0500",
      value: "Asia/Aqtau",
      label: "Asia/Aqtau (GMT +0500)",
    },
    {
      country_code: "KZ",
      offset: "+0500",
      value: "Asia/Aqtobe",
      label: "Asia/Aqtobe (GMT +0500)",
    },
    {
      country_code: "TM",
      offset: "+0500",
      value: "Asia/Ashgabat",
      label: "Asia/Ashgabat (GMT +0500)",
    },
    {
      country_code: "KZ",
      offset: "+0500",
      value: "Asia/Atyrau",
      label: "Asia/Atyrau (GMT +0500)",
    },
    {
      country_code: "IQ",
      offset: "+0300",
      value: "Asia/Baghdad",
      label: "Asia/Baghdad (GMT +0300)",
    },
    {
      country_code: "BH",
      offset: "+0300",
      value: "Asia/Bahrain",
      label: "Asia/Bahrain (GMT +0300)",
    },
    {
      country_code: "AZ",
      offset: "+0400",
      value: "Asia/Baku",
      label: "Asia/Baku (GMT +0400)",
    },
    {
      country_code: "TH",
      offset: "+0700",
      value: "Asia/Bangkok",
      label: "Asia/Bangkok (GMT +0700)",
    },
    {
      country_code: "RU",
      offset: "+0700",
      value: "Asia/Barnaul",
      label: "Asia/Barnaul (GMT +0700)",
    },
    {
      country_code: "LB",
      offset: "+0200",
      value: "Asia/Beirut",
      label: "Asia/Beirut (GMT +0200)",
    },
    {
      country_code: "KG",
      offset: "+0600",
      value: "Asia/Bishkek",
      label: "Asia/Bishkek (GMT +0600)",
    },
    {
      country_code: "BN",
      offset: "+0800",
      value: "Asia/Brunei",
      label: "Asia/Brunei (GMT +0800)",
    },
    {
      country_code: "RU",
      offset: "+0900",
      value: "Asia/Chita",
      label: "Asia/Chita (GMT +0900)",
    },
    {
      country_code: "MN",
      offset: "+0800",
      value: "Asia/Choibalsan",
      label: "Asia/Choibalsan (GMT +0800)",
    },
    {
      country_code: "LK",
      offset: "+0530",
      value: "Asia/Colombo",
      label: "Asia/Colombo (GMT +0530)",
    },
    {
      country_code: "SY",
      offset: "+0200",
      value: "Asia/Damascus",
      label: "Asia/Damascus (GMT +0200)",
    },
    {
      country_code: "BD",
      offset: "+0600",
      value: "Asia/Dhaka",
      label: "Asia/Dhaka (GMT +0600)",
    },
    {
      country_code: "TL",
      offset: "+0900",
      value: "Asia/Dili",
      label: "Asia/Dili (GMT +0900)",
    },
    {
      country_code: "AE",
      offset: "+0400",
      value: "Asia/Dubai",
      label: "Asia/Dubai (GMT +0400)",
    },
    {
      country_code: "TJ",
      offset: "+0500",
      value: "Asia/Dushanbe",
      label: "Asia/Dushanbe (GMT +0500)",
    },
    {
      country_code: "CY",
      offset: "+0200",
      value: "Asia/Famagusta",
      label: "Asia/Famagusta (GMT +0200)",
    },
    {
      country_code: "PS",
      offset: "+0200",
      value: "Asia/Gaza",
      label: "Asia/Gaza (GMT +0200)",
    },
    {
      country_code: "PS",
      offset: "+0200",
      value: "Asia/Hebron",
      label: "Asia/Hebron (GMT +0200)",
    },
    {
      country_code: "VN",
      offset: "+0700",
      value: "Asia/Ho_Chi_Minh",
      label: "Asia/Ho_Chi_Minh (GMT +0700)",
    },
    {
      country_code: "HK",
      offset: "+0800",
      value: "Asia/Hong_Kong",
      label: "Asia/Hong_Kong (GMT +0800)",
    },
    {
      country_code: "MN",
      offset: "+0700",
      value: "Asia/Hovd",
      label: "Asia/Hovd (GMT +0700)",
    },
    {
      country_code: "RU",
      offset: "+0800",
      value: "Asia/Irkutsk",
      label: "Asia/Irkutsk (GMT +0800)",
    },
    {
      country_code: "ID",
      offset: "+0700",
      value: "Asia/Jakarta",
      label: "Asia/Jakarta (GMT +0700)",
    },
    {
      country_code: "ID",
      offset: "+0900",
      value: "Asia/Jayapura",
      label: "Asia/Jayapura (GMT +0900)",
    },
    {
      country_code: "IL",
      offset: "+0200",
      value: "Asia/Jerusalem",
      label: "Asia/Jerusalem (GMT +0200)",
    },
    {
      country_code: "AF",
      offset: "+0430",
      value: "Asia/Kabul",
      label: "Asia/Kabul (GMT +0430)",
    },
    {
      country_code: "RU",
      offset: "+1200",
      value: "Asia/Kamchatka",
      label: "Asia/Kamchatka (GMT +1200)",
    },
    {
      country_code: "PK",
      offset: "+0500",
      value: "Asia/Karachi",
      label: "Asia/Karachi (GMT +0500)",
    },
    {
      country_code: "NP",
      offset: "+0545",
      value: "Asia/Kathmandu",
      label: "Asia/Kathmandu (GMT +0545)",
    },
    {
      country_code: "RU",
      offset: "+0900",
      value: "Asia/Khandyga",
      label: "Asia/Khandyga (GMT +0900)",
    },
    ASIA_KOLKATA_TIMEZONE,
    {
      country_code: "IN",
      offset: "+0530",
      value: "Asia/Calcutta",
      label: "Asia/Calcutta (GMT +0530)",
    },
    {
      country_code: "RU",
      offset: "+0700",
      value: "Asia/Krasnoyarsk",
      label: "Asia/Krasnoyarsk (GMT +0700)",
    },
    {
      country_code: "MY",
      offset: "+0800",
      value: "Asia/Kuala_Lumpur",
      label: "Asia/Kuala_Lumpur (GMT +0800)",
    },
    {
      country_code: "MY",
      offset: "+0800",
      value: "Asia/Kuching",
      label: "Asia/Kuching (GMT +0800)",
    },
    {
      country_code: "KW",
      offset: "+0300",
      value: "Asia/Kuwait",
      label: "Asia/Kuwait (GMT +0300)",
    },
    {
      country_code: "MO",
      offset: "+0800",
      value: "Asia/Macau",
      label: "Asia/Macau (GMT +0800)",
    },
    {
      country_code: "RU",
      offset: "+1100",
      value: "Asia/Magadan",
      label: "Asia/Magadan (GMT +1100)",
    },
    {
      country_code: "ID",
      offset: "+0800",
      value: "Asia/Makassar",
      label: "Asia/Makassar (GMT +0800)",
    },
    {
      country_code: "PH",
      offset: "+0800",
      value: "Asia/Manila",
      label: "Asia/Manila (GMT +0800)",
    },
    {
      country_code: "OM",
      offset: "+0400",
      value: "Asia/Muscat",
      label: "Asia/Muscat (GMT +0400)",
    },
    {
      country_code: "CY",
      offset: "+0200",
      value: "Asia/Nicosia",
      label: "Asia/Nicosia (GMT +0200)",
    },
    {
      country_code: "RU",
      offset: "+0700",
      value: "Asia/Novokuznetsk",
      label: "Asia/Novokuznetsk (GMT +0700)",
    },
    {
      country_code: "RU",
      offset: "+0700",
      value: "Asia/Novosibirsk",
      label: "Asia/Novosibirsk (GMT +0700)",
    },
    {
      country_code: "RU",
      offset: "+0600",
      value: "Asia/Omsk",
      label: "Asia/Omsk (GMT +0600)",
    },
    {
      country_code: "KZ",
      offset: "+0500",
      value: "Asia/Oral",
      label: "Asia/Oral (GMT +0500)",
    },
    {
      country_code: "KH",
      offset: "+0700",
      value: "Asia/Phnom_Penh",
      label: "Asia/Phnom_Penh (GMT +0700)",
    },
    {
      country_code: "ID",
      offset: "+0700",
      value: "Asia/Pontianak",
      label: "Asia/Pontianak (GMT +0700)",
    },
    {
      country_code: "KP",
      offset: "+0900",
      value: "Asia/Pyongyang",
      label: "Asia/Pyongyang (GMT +0900)",
    },
    {
      country_code: "QA",
      offset: "+0300",
      value: "Asia/Qatar",
      label: "Asia/Qatar (GMT +0300)",
    },
    {
      country_code: "KZ",
      offset: "+0600",
      value: "Asia/Qostanay",
      label: "Asia/Qostanay (GMT +0600)",
    },
    {
      country_code: "KZ",
      offset: "+0500",
      value: "Asia/Qyzylorda",
      label: "Asia/Qyzylorda (GMT +0500)",
    },
    {
      country_code: "SA",
      offset: "+0300",
      value: "Asia/Riyadh",
      label: "Asia/Riyadh (GMT +0300)",
    },
    {
      country_code: "RU",
      offset: "+1100",
      value: "Asia/Sakhalin",
      label: "Asia/Sakhalin (GMT +1100)",
    },
    {
      country_code: "UZ",
      offset: "+0500",
      value: "Asia/Samarkand",
      label: "Asia/Samarkand (GMT +0500)",
    },
    {
      country_code: "KR",
      offset: "+0900",
      value: "Asia/Seoul",
      label: "Asia/Seoul (GMT +0900)",
    },
    {
      country_code: "CN",
      offset: "+0800",
      value: "Asia/Shanghai",
      label: "Asia/Shanghai (GMT +0800)",
    },
    {
      country_code: "SG",
      offset: "+0800",
      value: "Asia/Singapore",
      label: "Asia/Singapore (GMT +0800)",
    },
    {
      country_code: "RU",
      offset: "+1100",
      value: "Asia/Srednekolymsk",
      label: "Asia/Srednekolymsk (GMT +1100)",
    },
    {
      country_code: "TW",
      offset: "+0800",
      value: "Asia/Taipei",
      label: "Asia/Taipei (GMT +0800)",
    },
    {
      country_code: "UZ",
      offset: "+0500",
      value: "Asia/Tashkent",
      label: "Asia/Tashkent (GMT +0500)",
    },
    {
      country_code: "GE",
      offset: "+0400",
      value: "Asia/Tbilisi",
      label: "Asia/Tbilisi (GMT +0400)",
    },
    {
      country_code: "IR",
      offset: "+0330",
      value: "Asia/Tehran",
      label: "Asia/Tehran (GMT +0330)",
    },
    {
      country_code: "BT",
      offset: "+0600",
      value: "Asia/Thimphu",
      label: "Asia/Thimphu (GMT +0600)",
    },
    {
      country_code: "JP",
      offset: "+0900",
      value: "Asia/Tokyo",
      label: "Asia/Tokyo (GMT +0900)",
    },
    {
      country_code: "RU",
      offset: "+0700",
      value: "Asia/Tomsk",
      label: "Asia/Tomsk (GMT +0700)",
    },
    {
      country_code: "MN",
      offset: "+0800",
      value: "Asia/Ulaanbaatar",
      label: "Asia/Ulaanbaatar (GMT +0800)",
    },
    {
      country_code: "CN",
      offset: "+0600",
      value: "Asia/Urumqi",
      label: "Asia/Urumqi (GMT +0600)",
    },
    {
      country_code: "RU",
      offset: "+1000",
      value: "Asia/Ust-Nera",
      label: "Asia/Ust-Nera (GMT +1000)",
    },
    {
      country_code: "LA",
      offset: "+0700",
      value: "Asia/Vientiane",
      label: "Asia/Vientiane (GMT +0700)",
    },
    {
      country_code: "RU",
      offset: "+1000",
      value: "Asia/Vladivostok",
      label: "Asia/Vladivostok (GMT +1000)",
    },
    {
      country_code: "RU",
      offset: "+0900",
      value: "Asia/Yakutsk",
      label: "Asia/Yakutsk (GMT +0900)",
    },
    {
      country_code: "MM",
      offset: "+0630",
      value: "Asia/Yangon",
      label: "Asia/Yangon (GMT +0630)",
    },
    {
      country_code: "RU",
      offset: "+0500",
      value: "Asia/Yekaterinburg",
      label: "Asia/Yekaterinburg (GMT +0500)",
    },
    {
      country_code: "AM",
      offset: "+0400",
      value: "Asia/Yerevan",
      label: "Asia/Yerevan (GMT +0400)",
    },
    {
      country_code: "PT",
      offset: "-0100",
      value: "Atlantic/Azores",
      label: "Atlantic/Azores (GMT -0100)",
    },
    {
      country_code: "BM",
      offset: "-0400",
      value: "Atlantic/Bermuda",
      label: "Atlantic/Bermuda (GMT -0400)",
    },
    {
      country_code: "ES",
      offset: "+0000",
      value: "Atlantic/Canary",
      label: "Atlantic/Canary (GMT +0000)",
    },
    {
      country_code: "CV",
      offset: "-0100",
      value: "Atlantic/Cape_Verde",
      label: "Atlantic/Cape_Verde (GMT -0100)",
    },
    {
      country_code: "FO",
      offset: "+0000",
      value: "Atlantic/Faroe",
      label: "Atlantic/Faroe (GMT +0000)",
    },
    {
      country_code: "PT",
      offset: "+0000",
      value: "Atlantic/Madeira",
      label: "Atlantic/Madeira (GMT +0000)",
    },
    {
      country_code: "IS",
      offset: "+0000",
      value: "Atlantic/Reykjavik",
      label: "Atlantic/Reykjavik (GMT +0000)",
    },
    {
      country_code: "GS",
      offset: "-0200",
      value: "Atlantic/South_Georgia",
      label: "Atlantic/South_Georgia (GMT -0200)",
    },
    {
      country_code: "SH",
      offset: "+0000",
      value: "Atlantic/St_Helena",
      label: "Atlantic/St_Helena (GMT +0000)",
    },
    {
      country_code: "FK",
      offset: "-0300",
      value: "Atlantic/Stanley",
      label: "Atlantic/Stanley (GMT -0300)",
    },
    {
      country_code: "AU",
      offset: "+1030",
      value: "Australia/Adelaide",
      label: "Australia/Adelaide (GMT +1030)",
    },
    {
      country_code: "AU",
      offset: "+1000",
      value: "Australia/Brisbane",
      label: "Australia/Brisbane (GMT +1000)",
    },
    {
      country_code: "AU",
      offset: "+1030",
      value: "Australia/Broken_Hill",
      label: "Australia/Broken_Hill (GMT +1030)",
    },
    {
      country_code: "AU",
      offset: "+0930",
      value: "Australia/Darwin",
      label: "Australia/Darwin (GMT +0930)",
    },
    {
      country_code: "AU",
      offset: "+0845",
      value: "Australia/Eucla",
      label: "Australia/Eucla (GMT +0845)",
    },
    {
      country_code: "AU",
      offset: "+1100",
      value: "Australia/Hobart",
      label: "Australia/Hobart (GMT +1100)",
    },
    {
      country_code: "AU",
      offset: "+1000",
      value: "Australia/Lindeman",
      label: "Australia/Lindeman (GMT +1000)",
    },
    {
      country_code: "AU",
      offset: "+1100",
      value: "Australia/Lord_Howe",
      label: "Australia/Lord_Howe (GMT +1100)",
    },
    {
      country_code: "AU",
      offset: "+1100",
      value: "Australia/Melbourne",
      label: "Australia/Melbourne (GMT +1100)",
    },
    {
      country_code: "AU",
      offset: "+0800",
      value: "Australia/Perth",
      label: "Australia/Perth (GMT +0800)",
    },
    {
      country_code: "AU",
      offset: "+1100",
      value: "Australia/Sydney",
      label: "Australia/Sydney (GMT +1100)",
    },
    {
      country_code: "NL",
      offset: "+0100",
      value: "Europe/Amsterdam",
      label: "Europe/Amsterdam (GMT +0100)",
    },
    {
      country_code: "AD",
      offset: "+0100",
      value: "Europe/Andorra",
      label: "Europe/Andorra (GMT +0100)",
    },
    {
      country_code: "RU",
      offset: "+0400",
      value: "Europe/Astrakhan",
      label: "Europe/Astrakhan (GMT +0400)",
    },
    {
      country_code: "GR",
      offset: "+0200",
      value: "Europe/Athens",
      label: "Europe/Athens (GMT +0200)",
    },
    {
      country_code: "RS",
      offset: "+0100",
      value: "Europe/Belgrade",
      label: "Europe/Belgrade (GMT +0100)",
    },
    {
      country_code: "DE",
      offset: "+0100",
      value: "Europe/Berlin",
      label: "Europe/Berlin (GMT +0100)",
    },
    {
      country_code: "SK",
      offset: "+0100",
      value: "Europe/Bratislava",
      label: "Europe/Bratislava (GMT +0100)",
    },
    {
      country_code: "BE",
      offset: "+0100",
      value: "Europe/Brussels",
      label: "Europe/Brussels (GMT +0100)",
    },
    {
      country_code: "RO",
      offset: "+0200",
      value: "Europe/Bucharest",
      label: "Europe/Bucharest (GMT +0200)",
    },
    {
      country_code: "HU",
      offset: "+0100",
      value: "Europe/Budapest",
      label: "Europe/Budapest (GMT +0100)",
    },
    {
      country_code: "DE",
      offset: "+0100",
      value: "Europe/Busingen",
      label: "Europe/Busingen (GMT +0100)",
    },
    {
      country_code: "MD",
      offset: "+0200",
      value: "Europe/Chisinau",
      label: "Europe/Chisinau (GMT +0200)",
    },
    {
      country_code: "DK",
      offset: "+0100",
      value: "Europe/Copenhagen",
      label: "Europe/Copenhagen (GMT +0100)",
    },
    {
      country_code: "IE",
      offset: "+0000",
      value: "Europe/Dublin",
      label: "Europe/Dublin (GMT +0000)",
    },
    {
      country_code: "GI",
      offset: "+0100",
      value: "Europe/Gibraltar",
      label: "Europe/Gibraltar (GMT +0100)",
    },
    {
      country_code: "GG",
      offset: "+0000",
      value: "Europe/Guernsey",
      label: "Europe/Guernsey (GMT +0000)",
    },
    {
      country_code: "FI",
      offset: "+0200",
      value: "Europe/Helsinki",
      label: "Europe/Helsinki (GMT +0200)",
    },
    {
      country_code: "IM",
      offset: "+0000",
      value: "Europe/Isle_of_Man",
      label: "Europe/Isle_of_Man (GMT +0000)",
    },
    {
      country_code: "TR",
      offset: "+0300",
      value: "Europe/Istanbul",
      label: "Europe/Istanbul (GMT +0300)",
    },
    {
      country_code: "JE",
      offset: "+0000",
      value: "Europe/Jersey",
      label: "Europe/Jersey (GMT +0000)",
    },
    {
      country_code: "RU",
      offset: "+0200",
      value: "Europe/Kaliningrad",
      label: "Europe/Kaliningrad (GMT +0200)",
    },
    {
      country_code: "UA",
      offset: "+0200",
      value: "Europe/Kiev",
      label: "Europe/Kiev (GMT +0200)",
    },
    {
      country_code: "RU",
      offset: "+0300",
      value: "Europe/Kirov",
      label: "Europe/Kirov (GMT +0300)",
    },
    {
      country_code: "PT",
      offset: "+0000",
      value: "Europe/Lisbon",
      label: "Europe/Lisbon (GMT +0000)",
    },
    {
      country_code: "SI",
      offset: "+0100",
      value: "Europe/Ljubljana",
      label: "Europe/Ljubljana (GMT +0100)",
    },
    {
      country_code: "GB",
      offset: "+0000",
      value: "Europe/London",
      label: "Europe/London (GMT +0000)",
    },
    {
      country_code: "LU",
      offset: "+0100",
      value: "Europe/Luxembourg",
      label: "Europe/Luxembourg (GMT +0100)",
    },
    {
      country_code: "ES",
      offset: "+0100",
      value: "Europe/Madrid",
      label: "Europe/Madrid (GMT +0100)",
    },
    {
      country_code: "MT",
      offset: "+0100",
      value: "Europe/Malta",
      label: "Europe/Malta (GMT +0100)",
    },
    {
      country_code: "AX",
      offset: "+0200",
      value: "Europe/Mariehamn",
      label: "Europe/Mariehamn (GMT +0200)",
    },
    {
      country_code: "BY",
      offset: "+0300",
      value: "Europe/Minsk",
      label: "Europe/Minsk (GMT +0300)",
    },
    {
      country_code: "MC",
      offset: "+0100",
      value: "Europe/Monaco",
      label: "Europe/Monaco (GMT +0100)",
    },
    {
      country_code: "RU",
      offset: "+0300",
      value: "Europe/Moscow",
      label: "Europe/Moscow (GMT +0300)",
    },
    {
      country_code: "NO",
      offset: "+0100",
      value: "Europe/Oslo",
      label: "Europe/Oslo (GMT +0100)",
    },
    {
      country_code: "FR",
      offset: "+0100",
      value: "Europe/Paris",
      label: "Europe/Paris (GMT +0100)",
    },
    {
      country_code: "ME",
      offset: "+0100",
      value: "Europe/Podgorica",
      label: "Europe/Podgorica (GMT +0100)",
    },
    {
      country_code: "CZ",
      offset: "+0100",
      value: "Europe/Prague",
      label: "Europe/Prague (GMT +0100)",
    },
    {
      country_code: "LV",
      offset: "+0200",
      value: "Europe/Riga",
      label: "Europe/Riga (GMT +0200)",
    },
    {
      country_code: "IT",
      offset: "+0100",
      value: "Europe/Rome",
      label: "Europe/Rome (GMT +0100)",
    },
    {
      country_code: "RU",
      offset: "+0400",
      value: "Europe/Samara",
      label: "Europe/Samara (GMT +0400)",
    },
    {
      country_code: "SM",
      offset: "+0100",
      value: "Europe/San_Marino",
      label: "Europe/San_Marino (GMT +0100)",
    },
    {
      country_code: "BA",
      offset: "+0100",
      value: "Europe/Sarajevo",
      label: "Europe/Sarajevo (GMT +0100)",
    },
    {
      country_code: "RU",
      offset: "+0400",
      value: "Europe/Saratov",
      label: "Europe/Saratov (GMT +0400)",
    },
    {
      country_code: "UA",
      offset: "+0300",
      value: "Europe/Simferopol",
      label: "Europe/Simferopol (GMT +0300)",
    },
    {
      country_code: "MK",
      offset: "+0100",
      value: "Europe/Skopje",
      label: "Europe/Skopje (GMT +0100)",
    },
    {
      country_code: "BG",
      offset: "+0200",
      value: "Europe/Sofia",
      label: "Europe/Sofia (GMT +0200)",
    },
    {
      country_code: "SE",
      offset: "+0100",
      value: "Europe/Stockholm",
      label: "Europe/Stockholm (GMT +0100)",
    },
    {
      country_code: "EE",
      offset: "+0200",
      value: "Europe/Tallinn",
      label: "Europe/Tallinn (GMT +0200)",
    },
    {
      country_code: "AL",
      offset: "+0100",
      value: "Europe/Tirane",
      label: "Europe/Tirane (GMT +0100)",
    },
    {
      country_code: "RU",
      offset: "+0400",
      value: "Europe/Ulyanovsk",
      label: "Europe/Ulyanovsk (GMT +0400)",
    },
    {
      country_code: "UA",
      offset: "+0200",
      value: "Europe/Uzhgorod",
      label: "Europe/Uzhgorod (GMT +0200)",
    },
    {
      country_code: "LI",
      offset: "+0100",
      value: "Europe/Vaduz",
      label: "Europe/Vaduz (GMT +0100)",
    },
    {
      country_code: "VA",
      offset: "+0100",
      value: "Europe/Vatican",
      label: "Europe/Vatican (GMT +0100)",
    },
    {
      country_code: "AT",
      offset: "+0100",
      value: "Europe/Vienna",
      label: "Europe/Vienna (GMT +0100)",
    },
    {
      country_code: "LT",
      offset: "+0200",
      value: "Europe/Vilnius",
      label: "Europe/Vilnius (GMT +0200)",
    },
    {
      country_code: "RU",
      offset: "+0300",
      value: "Europe/Volgograd",
      label: "Europe/Volgograd (GMT +0300)",
    },
    {
      country_code: "PL",
      offset: "+0100",
      value: "Europe/Warsaw",
      label: "Europe/Warsaw (GMT +0100)",
    },
    {
      country_code: "HR",
      offset: "+0100",
      value: "Europe/Zagreb",
      label: "Europe/Zagreb (GMT +0100)",
    },
    {
      country_code: "UA",
      offset: "+0200",
      value: "Europe/Zaporozhye",
      label: "Europe/Zaporozhye (GMT +0200)",
    },
    {
      country_code: "CH",
      offset: "+0100",
      value: "Europe/Zurich",
      label: "Europe/Zurich (GMT +0100)",
    },
    {
      country_code: "MG",
      offset: "+0300",
      value: "Indian/Antananarivo",
      label: "Indian/Antananarivo (GMT +0300)",
    },
    {
      country_code: "IO",
      offset: "+0600",
      value: "Indian/Chagos",
      label: "Indian/Chagos (GMT +0600)",
    },
    {
      country_code: "CX",
      offset: "+0700",
      value: "Indian/Christmas",
      label: "Indian/Christmas (GMT +0700)",
    },
    {
      country_code: "CC",
      offset: "+0630",
      value: "Indian/Cocos",
      label: "Indian/Cocos (GMT +0630)",
    },
    {
      country_code: "KM",
      offset: "+0300",
      value: "Indian/Comoro",
      label: "Indian/Comoro (GMT +0300)",
    },
    {
      country_code: "TF",
      offset: "+0500",
      value: "Indian/Kerguelen",
      label: "Indian/Kerguelen (GMT +0500)",
    },
    {
      country_code: "SC",
      offset: "+0400",
      value: "Indian/Mahe",
      label: "Indian/Mahe (GMT +0400)",
    },
    {
      country_code: "MV",
      offset: "+0500",
      value: "Indian/Maldives",
      label: "Indian/Maldives (GMT +0500)",
    },
    {
      country_code: "MU",
      offset: "+0400",
      value: "Indian/Mauritius",
      label: "Indian/Mauritius (GMT +0400)",
    },
    {
      country_code: "YT",
      offset: "+0300",
      value: "Indian/Mayotte",
      label: "Indian/Mayotte (GMT +0300)",
    },
    {
      country_code: "RE",
      offset: "+0400",
      value: "Indian/Reunion",
      label: "Indian/Reunion (GMT +0400)",
    },
    {
      country_code: "WS",
      offset: "+1300",
      value: "Pacific/Apia",
      label: "Pacific/Apia (GMT +1300)",
    },
    {
      country_code: "NZ",
      offset: "+1300",
      value: "Pacific/Auckland",
      label: "Pacific/Auckland (GMT +1300)",
    },
    {
      country_code: "PG",
      offset: "+1100",
      value: "Pacific/Bougainville",
      label: "Pacific/Bougainville (GMT +1100)",
    },
    {
      country_code: "NZ",
      offset: "+1345",
      value: "Pacific/Chatham",
      label: "Pacific/Chatham (GMT +1345)",
    },
    {
      country_code: "FM",
      offset: "+1000",
      value: "Pacific/Chuuk",
      label: "Pacific/Chuuk (GMT +1000)",
    },
    {
      country_code: "CL",
      offset: "-0500",
      value: "Pacific/Easter",
      label: "Pacific/Easter (GMT -0500)",
    },
    {
      country_code: "VU",
      offset: "+1100",
      value: "Pacific/Efate",
      label: "Pacific/Efate (GMT +1100)",
    },
    {
      country_code: "TK",
      offset: "+1300",
      value: "Pacific/Fakaofo",
      label: "Pacific/Fakaofo (GMT +1300)",
    },
    {
      country_code: "FJ",
      offset: "+1200",
      value: "Pacific/Fiji",
      label: "Pacific/Fiji (GMT +1200)",
    },
    {
      country_code: "TV",
      offset: "+1200",
      value: "Pacific/Funafuti",
      label: "Pacific/Funafuti (GMT +1200)",
    },
    {
      country_code: "EC",
      offset: "-0600",
      value: "Pacific/Galapagos",
      label: "Pacific/Galapagos (GMT -0600)",
    },
    {
      country_code: "PF",
      offset: "-0900",
      value: "Pacific/Gambier",
      label: "Pacific/Gambier (GMT -0900)",
    },
    {
      country_code: "SB",
      offset: "+1100",
      value: "Pacific/Guadalcanal",
      label: "Pacific/Guadalcanal (GMT +1100)",
    },
    {
      country_code: "GU",
      offset: "+1000",
      value: "Pacific/Guam",
      label: "Pacific/Guam (GMT +1000)",
    },
    {
      country_code: "US",
      offset: "-1000",
      value: "Pacific/Honolulu",
      label: "Pacific/Honolulu (GMT -1000)",
    },
    {
      country_code: "KI",
      offset: "+1400",
      value: "Pacific/Kiritimati",
      label: "Pacific/Kiritimati (GMT +1400)",
    },
    {
      country_code: "FM",
      offset: "+1100",
      value: "Pacific/Kosrae",
      label: "Pacific/Kosrae (GMT +1100)",
    },
    {
      country_code: "MH",
      offset: "+1200",
      value: "Pacific/Kwajalein",
      label: "Pacific/Kwajalein (GMT +1200)",
    },
    {
      country_code: "MH",
      offset: "+1200",
      value: "Pacific/Majuro",
      label: "Pacific/Majuro (GMT +1200)",
    },
    {
      country_code: "PF",
      offset: "-0930",
      value: "Pacific/Marquesas",
      label: "Pacific/Marquesas (GMT -0930)",
    },
    {
      country_code: "UM",
      offset: "-1100",
      value: "Pacific/Midway",
      label: "Pacific/Midway (GMT -1100)",
    },
    {
      country_code: "NR",
      offset: "+1200",
      value: "Pacific/Nauru",
      label: "Pacific/Nauru (GMT +1200)",
    },
    {
      country_code: "NU",
      offset: "-1100",
      value: "Pacific/Niue",
      label: "Pacific/Niue (GMT -1100)",
    },
    {
      country_code: "NF",
      offset: "+1200",
      value: "Pacific/Norfolk",
      label: "Pacific/Norfolk (GMT +1200)",
    },
    {
      country_code: "NC",
      offset: "+1100",
      value: "Pacific/Noumea",
      label: "Pacific/Noumea (GMT +1100)",
    },
    {
      country_code: "AS",
      offset: "-1100",
      value: "Pacific/Pago_Pago",
      label: "Pacific/Pago_Pago (GMT -1100)",
    },
    {
      country_code: "PW",
      offset: "+0900",
      value: "Pacific/Palau",
      label: "Pacific/Palau (GMT +0900)",
    },
    {
      country_code: "PN",
      offset: "-0800",
      value: "Pacific/Pitcairn",
      label: "Pacific/Pitcairn (GMT -0800)",
    },
    {
      country_code: "FM",
      offset: "+1100",
      value: "Pacific/Pohnpei",
      label: "Pacific/Pohnpei (GMT +1100)",
    },
    {
      country_code: "PG",
      offset: "+1000",
      value: "Pacific/Port_Moresby",
      label: "Pacific/Port_Moresby (GMT +1000)",
    },
    {
      country_code: "CK",
      offset: "-1000",
      value: "Pacific/Rarotonga",
      label: "Pacific/Rarotonga (GMT -1000)",
    },
    {
      country_code: "MP",
      offset: "+1000",
      value: "Pacific/Saipan",
      label: "Pacific/Saipan (GMT +1000)",
    },
    {
      country_code: "PF",
      offset: "-1000",
      value: "Pacific/Tahiti",
      label: "Pacific/Tahiti (GMT -1000)",
    },
    {
      country_code: "KI",
      offset: "+1200",
      value: "Pacific/Tarawa",
      label: "Pacific/Tarawa (GMT +1200)",
    },
    {
      country_code: "TO",
      offset: "+1300",
      value: "Pacific/Tongatapu",
      label: "Pacific/Tongatapu (GMT +1300)",
    },
    {
      country_code: "UM",
      offset: "+1200",
      value: "Pacific/Wake",
      label: "Pacific/Wake (GMT +1200)",
    },
    {
      country_code: "WF",
      offset: "+1200",
      value: "Pacific/Wallis",
      label: "Pacific/Wallis (GMT +1200)",
    },
  ],

  // US timezones
  us_timezones: [
    {
      value: "America/Adak",
      country_code: "US",
      offset: "-1000",
      label: "America/Adak (GMT -1000)",
    },
    {
      value: "America/Anchorage",
      country_code: "US",
      offset: "-0900",
      label: "America/Anchorage (GMT -0900)",
    },
    {
      value: "America/Boise",
      country_code: "US",
      offset: "-0700",
      label: "America/Boise (GMT -0700)",
    },
    {
      value: "America/Chicago",
      country_code: "US",
      offset: "-0600",
      label: "America/Chicago (GMT -0600)",
    },
    {
      value: "America/Denver",
      country_code: "US",
      offset: "-0700",
      label: "America/Denver (GMT -0700)",
    },
    {
      value: "America/Detroit",
      country_code: "US",
      offset: "-0500",
      label: "America/Detroit (GMT -0500)",
    },
    {
      value: "America/Indiana/Indianapolis",
      country_code: "US",
      offset: "-0500",
      label: "America/Indiana/Indianapolis (GMT -0500)",
    },
    {
      value: "America/Indiana/Knox",
      country_code: "US",
      offset: "-0600",
      label: "America/Indiana/Knox (GMT -0600)",
    },
    {
      value: "America/Indiana/Marengo",
      country_code: "US",
      offset: "-0500",
      label: "America/Indiana/Marengo (GMT -0500)",
    },
    {
      value: "America/Indiana/Petersburg",
      country_code: "US",
      offset: "-0500",
      label: "America/Indiana/Petersburg (GMT -0500)",
    },
    {
      value: "America/Indiana/Tell_City",
      country_code: "US",
      offset: "-0600",
      label: "America/Indiana/Tell_City (GMT -0600)",
    },
    {
      value: "America/Indiana/Vevay",
      country_code: "US",
      offset: "-0500",
      label: "America/Indiana/Vevay (GMT -0500)",
    },
    {
      value: "America/Indiana/Vincennes",
      country_code: "US",
      offset: "-0500",
      label: "America/Indiana/Vincennes (GMT -0500)",
    },
    {
      value: "America/Indiana/Winamac",
      country_code: "US",
      offset: "-0500",
      label: "America/Indiana/Winamac (GMT -0500)",
    },
    {
      value: "America/Juneau",
      country_code: "US",
      offset: "-0900",
      label: "America/Juneau (GMT -0900)",
    },
    {
      value: "America/Kentucky/Louisville",
      country_code: "US",
      offset: "-0500",
      label: "America/Kentucky/Louisville (GMT -0500)",
    },
    {
      value: "America/Kentucky/Monticello",
      country_code: "US",
      offset: "-0500",
      label: "America/Kentucky/Monticello (GMT -0500)",
    },
    { ...timezone_constants.default_internation_timezone },
    {
      value: "America/Menominee",
      country_code: "US",
      offset: "-0600",
      label: "America/Menominee (GMT -0600)",
    },
    {
      value: "America/Metlakatla",
      country_code: "US",
      offset: "-0900",
      label: "America/Metlakatla (GMT -0900)",
    },
    {
      value: "America/New_York",
      country_code: "US",
      offset: "-0500",
      label: "America/New_York (GMT -0500)",
    },
    {
      value: "America/Nome",
      country_code: "US",
      offset: "-0900",
      label: "America/Nome (GMT -0900)",
    },
    {
      value: "America/North_Dakota/Beulah",
      country_code: "US",
      offset: "-0600",
      label: "America/North_Dakota/Beulah (GMT -0600)",
    },
    {
      value: "America/North_Dakota/Center",
      country_code: "US",
      offset: "-0600",
      label: "America/North_Dakota/Center (GMT -0600)",
    },
    {
      value: "America/North_Dakota/New_Salem",
      country_code: "US",
      offset: "-0600",
      label: "America/North_Dakota/New_Salem (GMT -0600)",
    },
    {
      value: "America/Phoenix",
      country_code: "US",
      offset: "-0700",
      label: "America/Phoenix (GMT -0700)",
    },
    {
      value: "America/Sitka",
      country_code: "US",
      offset: "-0900",
      label: "America/Sitka (GMT -0900)",
    },
    {
      value: "America/Yakutat",
      country_code: "US",
      offset: "-0900",
      label: "America/Yakutat (GMT -0900)",
    },
  ],
  indian_timezones: [ASIA_KOLKATA_TIMEZONE],
};

export const us_and_indian_timezones = [
  ...timezones.us_timezones,
  ...timezones.indian_timezones,
];

export default timezones;
